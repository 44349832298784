import React from 'react';
import styled from 'styled-components';

// Styles
import theme from '../../styles/theme';

const { color } = theme;

const SVGStyle = styled.svg`
  fill: ${color.background};
`;

const Logo = () => (
  <SVGStyle viewBox="0 0 834.53 594.78" className="logo">
    <path
      d="M331.38,86.55c26.75,4.79,52.54,9.42,78.33,14a9.9,9.9,0,0,0,3.94.32C432.54,96,452.13,95.74,471.18,92c8-1.58,15.44-5.15,23.6-6,16.44-1.76,32.06,2.4,47.68,6.5,22.83,6,44.6,15.11,66.76,23.07,10.32,3.7,21.34,5,31.47,9.43a153.15,153.15,0,0,1,23.69,12.61c4.53,3,5.67,6.77,3.33,10.48s-6.64,4.22-11.31,1.29a127.53,127.53,0,0,0-39.21-16.68c-18.8-4.38-36.25-12.89-54.52-18.95-19.06-6.33-38.15-12.51-58.38-13.92a36.1,36.1,0,0,0-14.53,1.83c-16.79,5.91-34.41,7-51.78,9.51-6.68,1-13.34,1.68-20,3.46-7.65,2.06-15.52-.47-23.16-1.86-35.11-6.39-70.05-13.82-105.48-18.22-28.65-3.55-57.44-3.36-86.24-.68-14.53,1.35-28.86,3.91-43.28,6-18.1,2.6-35.4-1.45-52.13-8.19a180.22,180.22,0,0,1-19.45-8.84c-10.18-5.61-21.16-7.61-32.49-8.51-14.4-1.14-28.35,1.35-42.48,4.05-28.42,5.43-57,9.83-86.21,6.88-5.22-.52-8.27-3.1-8.11-7.46s3.21-6.85,8.69-6.73c11.64.24,23.27,1.36,34.93.08C-18.25,69,.8,66.4,19.71,62.53c25.46-5.22,50.58-4.6,74.51,7.28,13.85,6.87,28,13.06,43.4,15.52,11.22,1.79,22.16,0,33.18-1.79a435.67,435.67,0,0,1,114-3.84c7.44.74,7.43.89,7.11-6.3-.3-6.86-.09-13.65,2.1-20.25a31.45,31.45,0,0,1,4.68-9.33c4.66-6.09,10.34-11.07,18.5-9.19,9.08,2.08,16.3,7.06,17.39,17.41.76,7.09,1.62,14.19,1.14,21.38C335.41,77.94,335,82.34,331.38,86.55Z"
      transform="translate(128.99 48.24)"
    />
    <path
      d="M311.74,325.88a178.31,178.31,0,0,1-2.56,37.7c-2.11,11.94-9.32,19.17-21.33,21.27s-24.27,1.4-36.17-.4c-19.34-2.93-26.28-11.29-26.55-31-.2-15,1.82-29.9,7.9-43.88s14.94-25.11,29.33-31.11c8.64-3.61,17.17-3.37,25.44,1.22,11.66,6.49,21.92,14.37,23.82,28.75C312.38,314.16,311.74,320.06,311.74,325.88Z"
      transform="translate(128.99 48.24)"
    />
    <path
      d="M556.4,491.59a241,241,0,0,1,2.56-36.3c.57-4.18,3.95-7.14,8.67-6.16,11.21,2.32,22.86,2.17,33.78,6.05,5.49,2,10.89,4,15.6,7.63,9.64,7.35,11.71,17.76,5.42,28.18-4.58,7.59-11.4,12.94-18.7,17.57-3.19,2-3.14,3.45-1.11,6.07,5.12,6.58,11.4,11,19.94,11.92,6,.66,7.69,3.32,6.46,9.12-1.16,5.43-6.09,9-10.79,7.72-13-3.5-23.69-10.34-30.94-21.94-1.24-2-2.12-2.72-4.63-1.63-8.5,3.67-8.56,3.53-7.73,12.93.53,6.12-1.14,8.84-5.65,9.19-5.6.42-9.78-3-10.32-8.51C557.6,519.52,556.54,505.58,556.4,491.59Zm16.73,13.46c10.8-3.16,20.72-6.89,29.08-13.74,8.66-7.1,7.42-15-2.9-19.45-7.11-3.06-14.69-4-22.31-4.86-2.51-.27-3.59.43-3.65,3.12C573.11,481.59,572.16,493.05,573.13,505.05Z"
      transform="translate(128.99 48.24)"
    />
    <path
      d="M547.05,479.53c-.48,10.27-7.24,17.29-14.87,23.72a33.21,33.21,0,0,1-7.22,5.18c-4.34,2-3,4.2-.9,6.76,5,6.21,11.05,10.39,19.21,11.32,6.21.71,7.86,3.13,6.76,8.89s-6.11,9.23-11,7.91c-12.68-3.4-23.19-10-30.26-21.25-1.88-3-3.49-3.4-6.23-1.69a9.67,9.67,0,0,1-3.19,1.37c-4,.74-4.94,3.2-4.09,6.83a26.58,26.58,0,0,1,.7,4.42c.25,4,.32,7.92-4.9,8.86-5,.91-10.43-2.3-10.91-7.17-2.47-25-4.49-50.08-.64-75.15,1.62-10.56,3.3-11.08,14.22-9.37,9.34,1.47,18.85,1.94,27.94,4.84,8.31,2.65,16.31,5.87,21.72,13.28A18.92,18.92,0,0,1,547.05,479.53ZM494.34,486a69.27,69.27,0,0,0-.42,16.46c.09,1.27-.22,2.9,2,2.16,10.29-3.38,20.53-7,28.6-14.56,6.83-6.41,5.63-13.4-2.82-17.5-7.59-3.68-15.85-4.71-24.14-5.53-2.41-.24-3.31.66-3.28,3C494.38,475.31,494.34,480.64,494.34,486Z"
      transform="translate(128.99 48.24)"
    />
    <path
      d="M183.92-47.14c9.15.32,16.9,4,24.93,6.5,4.57,1.43,9,3.29,13.47,5.08a10.59,10.59,0,0,0,7.26.07c26.34-7.48,53.2-12.45,80.58-12.24a263.28,263.28,0,0,1,91.09,17.36,9.65,9.65,0,0,0,7.12.21,201.75,201.75,0,0,1,25.81-7.28A29.64,29.64,0,0,1,454.07-35a163.3,163.3,0,0,1,18.46,10.84c3.11,2.12,5,4.82,2.64,8.36s-5.68,2.64-8.77.72c-4.1-2.53-8.26-5-12.22-7.68C446.55-28,438.44-28.1,430-25.48c-7.29,2.26-14.63,4.36-21.87,6.77a9.73,9.73,0,0,1-7.12-.06C364.32-32.92,326.52-39.71,287.2-35.71a302.52,302.52,0,0,0-58.43,11.78,10.61,10.61,0,0,1-7.58-.42c-6.77-2.72-13.94-4.54-20.48-7.69-13.05-6.29-25.51-3.92-38,1.34-5.35,2.26-10.65,4.63-16,7-3.31,1.46-5.44-.07-7-2.81a4.37,4.37,0,0,1,1.9-6.4C155.2-39.86,169-45.88,183.92-47.14Z"
      transform="translate(128.99 48.24)"
    />
    <path
      d="M376.28,483.17c3.16,1.29,6.11,2.28,8.86,3.66,9.88,4.93,12.21,12.58,6.81,22.26-3.9,7-9.88,12.06-16.33,16.56-11,7.67-22.91,13.37-36.21,15.88-6.94,1.31-9.56-.43-10.49-7.52-3.18-24.2-2.74-48.47-.77-72.72.76-9.41,4.38-11.69,13.72-10,10.41,1.93,21,3.2,30.86,7.39,3.54,1.5,7,3.1,9.81,5.79,4.42,4.24,4.61,8,.42,12.55C381,479.21,378.63,481.05,376.28,483.17Zm-35.45,26.46c-.26,3.22.95,8.9,2.42,14.53.35,1.33.54,3,2.9,2.26,10.19-3.15,20.31-6.53,28.31-13.93,6.59-6.08,5-12.52-3.37-15.47-6.59-2.32-13.31-2.29-20.11-1.21C339,497.73,341.64,495.71,340.83,509.63Zm11-42.48a55.7,55.7,0,0,0-7,.05c-4.58.56-3,4.63-3.31,7.12-.32,2.86-.81,6.27,2.1,8.55.1.08.32.05.48,0,7.36-1.16,14.94-1.78,21.26-6.2,3.87-2.7,3.17-6.51-1.29-8.3C360.18,466.81,356,466.87,351.84,467.15Z"
      transform="translate(128.99 48.24)"
    />
    <path
      d="M-63,499c0-21.19,12.79-40.37,31.43-47.19A34.16,34.16,0,0,1,9.13,465.14c16.7,25.07,4,62.94-24.37,72.92C-33.7,544.55-53.37,534.7-60,515.6A45.2,45.2,0,0,1-63,499ZM.09,490.83c.3-3.93-.81-8.46-2.49-12.82a18.21,18.21,0,0,0-21.75-11.32c-17.79,4.23-28.72,27.67-21.22,45.51,6,14.36,21.42,17.93,32.88,7.23C-4.58,512.05.36,502.94.09,490.83Z"
      transform="translate(128.99 48.24)"
    />
    <path
      d="M-109.92,529.18c0,1.16-.06,2.33,0,3.49.28,4.22-.37,7.79-5.58,8.26s-9.83-2.6-10.28-7.42c-2.37-25-4.45-50.1-.54-75.18,1.35-8.68,2.84-10.32,9-10.36,5.41,0,7.79,2.92,6.93,8.25-1.56,9.68-3.2,19.37-3.33,29.21,0,3.4-.83,8,2.71,9.39,3.85,1.53,6-2.78,8-5.47,8.39-11.19,14.88-23.58,22-35.55,3.63-6.1,8.32-6.48,13.07-1.13,2.15,2.43,2.56,4.72.74,7.64-6.23,10-12.11,20.2-18.49,30.09-3.74,5.81-3.43,10.85.43,16.45,5.86,8.51,12.58,16.3,19.07,24.29,3.61,4.45,3.68,8.34.38,12.11-3.12,3.57-6.22,3.73-9.74.42a298.24,298.24,0,0,1-24.23-25.16c-1.62-1.94-3.35-3.54-5.89-2.58a6.6,6.6,0,0,0-4.17,5.76c-.24,2.47,0,5,0,7.49Z"
      transform="translate(128.99 48.24)"
    />
    <path
      d="M403.3,490.39l1.48-32c.35-7.72,3.11-10.49,10.59-10.75,15.28-.53,30,2.93,44.83,5.94,3.68.74,6.67,2.87,6,7.21s-3.79,5.46-7.76,5.14c-6.3-.5-12.61-.89-18.91-1.47-4.15-.38-8.31-.74-12.44,0a11.1,11.1,0,0,0-7.8,16c1.45,2.77,3.84,1.47,5.73,1.12,8.33-1.56,16.64-3.25,25.18-3a7.51,7.51,0,0,1,7.71,7.85c0,4.32-2.21,6.55-7,6.52a95.55,95.55,0,0,0-24.6,3.47c-4.82,1.25-7.09,3.73-6.34,9.26,2.78,20.59,8,23.16,25.53,18.36,7.06-1.93,14.25-3.45,21.26-5.54,3.32-1,5.53-.39,7,2.63s1.88,6.72-1.62,8.31c-14.56,6.58-29.31,13.18-45.86,11.34-11.32-1.25-18.73-10.29-21.29-24.51C403.33,507.18,403.42,498,403.3,490.39Z"
      transform="translate(128.99 48.24)"
    />
    <path
      d="M57.22,98.76c14-1,25.66,4.66,36.23,13.07,7.41,5.9,14.69,12,22,18a11.6,11.6,0,0,1,3.22,3.74c1.44,3.11,1.25,6.26-1.56,8.61-3.35,2.81-6.74,2.26-9.94-.42-6-5-12.35-9.66-17.87-15.15C75.5,113,59,111.85,41.12,114.52c-32.37,4.83-64,12.54-95.33,22-9.17,2.77-18.57,4.8-27.89,7.08-4.51,1.11-8.44-.92-9.63-4.79-1.12-3.64,1.38-7.74,5.93-8.88,39.31-9.9,77.91-22.81,118.2-28.6C40.63,100.13,49,99.6,57.22,98.76Z"
      transform="translate(128.99 48.24)"
    />
    <path
      d="M635.66,208.26c-.06,4.35-5.8,8.73-11.23,5.34-8.56-5.34-17.18-10.66-26.11-15.36-22.56-11.88-42.33-28-63.67-41.66-13.18-8.47-27-15.51-42.51-18.92a24.47,24.47,0,0,0-13.69.8c-8.8,3.08-16.94,7.52-25.26,11.63-3.29,1.62-6.46,1.58-9.11-1.25-2.15-2.3-2.4-5-1.2-7.87a7,7,0,0,1,3.5-3.39c8.05-4.3,16.3-8.16,24.72-11.7,10.41-4.39,20.76-3.67,31.21-.46a157.75,157.75,0,0,1,46.95,23.7C575,168,602.32,184.3,629.94,200.18,632.94,201.9,635.53,203.84,635.66,208.26Z"
      transform="translate(128.99 48.24)"
    />
    <path
      d="M81.88,542.63c-2.28,0-4-1.61-4.92-4.22-1.64-4.51-3.56-8.94-4.9-13.54-.72-2.5-1.94-3.19-4.23-2.92C57,523.24,46.09,524.5,35.4,527c-2.36.55-3,2.48-4,4.1-1.95,3.06-3,6.67-5.87,9.18-2.53,2.21-5.31,3.92-8.53,2.08-3.44-2-3.67-5.39-2.44-8.8,3-8.43,6.24-16.8,9.34-25.21,5.27-14.33,10.46-28.7,17.2-42.43,2.27-4.63,4.51-9.31,8.15-13.09,4-4.13,8.07-4.15,12.08,0,3.32,3.41,5.18,7.7,6.91,12,4.7,11.77,7.74,24,10.74,36.31.58,2.39,1.42,4,3.84,5.28,4.32,2.24,5.58,6.1,2.87,9.46-2.29,2.83-1.23,4.83.13,7.13A21.26,21.26,0,0,1,89,536.36C88.6,540.21,86.37,542.33,81.88,542.63Zm-27-71.5C46.7,484.31,42.26,498,38.34,512.63l24.43-3.87c3-.48,3.86-1.9,2.88-5-1.8-5.66-3.05-11.49-4.78-17.18C59.36,481.61,58.41,476.37,54.87,471.13Z"
      transform="translate(128.99 48.24)"
    />
    <path
      d="M234.23,542.64c-2.63,0-4.34-1.58-5.3-4.19-1.64-4.51-3.55-9-4.9-13.55-.72-2.46-1.88-3.23-4.21-2.95-10.87,1.28-21.74,2.55-32.43,5-2.37.55-3,2.46-4,4.08-2,3.06-3,6.68-5.86,9.19-2.53,2.21-5.3,3.93-8.53,2.11-3.47-2-3.7-5.4-2.48-8.79,3.37-9.35,6.91-18.64,10.35-28,5.23-14.17,10.44-28.36,17.28-41.86,2-4,4.11-8,7.37-11.23,3.68-3.63,7.65-3.7,11.39-.12,2.94,2.82,4.72,6.4,6.31,10.09,5.28,12.28,8.39,25.22,11.61,38.12.68,2.74,1.6,4.65,4.33,6.07,4,2.08,5.13,6,2.53,9.17-2.27,2.81-1.29,4.81.08,7.14A21.44,21.44,0,0,1,241,536.31C240.58,540.18,238.33,542.26,234.23,542.64ZM190.3,512.37c8.82-.74,17.13-2.54,25.49-4,2.61-.46,2.51-1.89,2-3.73-2.35-8.44-4.63-16.89-7.13-25.28-.78-2.6-1.44-5.38-3.91-7.86C198.57,484.12,194.37,498,190.3,512.37Z"
      transform="translate(128.99 48.24)"
    />
    <path
      d="M54.77,149c18.83-1,36.6,2.81,53.11,12.06a22,22,0,0,1,6.41,5.48c2.4,3,2.43,6.2-.13,9.07-2.71,3-6.2,3.54-9.4,1.1C90.88,166.18,74.92,163.09,58,163c-12.5-.08-25.08,0-37.28,2.37-10,2-19.71,5.78-29.89,7.17-21.43,2.94-41.48,10-60.68,19.72-4.67,2.38-8.78,1-10.57-3s-.08-7.58,4.7-10c19.45-9.93,39.78-17.07,61.5-20.36a147.17,147.17,0,0,0,26.88-6.48c9.09-3.21,19-2.65,28.65-3.31C45.76,148.77,50.27,149,54.77,149Z"
      transform="translate(128.99 48.24)"
    />
    <path
      d="M479.39,171.19c14.12-.12,22.74,5.92,30.62,13.36a18.07,18.07,0,0,0,9,5c5.31,1.09,9.76,4.15,14.08,7.22,11.37,8.1,24.28,13.67,35.62,21.78C583.29,229,596.71,240.94,610,253c3.88,3.51,4.2,7.74,1.15,10.9s-6.81,2.94-10.61-.37c-12.93-11.22-25.31-23.17-39.58-32.67s-28.39-19.34-43.79-26.38c-9-4.1-16.43-9.35-23.91-15.2-5.92-4.62-12.74-4.39-19.49-2.69s-13.66,3.91-20.45,6c-5.6,1.71-9.06.5-10.71-3.93-1.55-4.14.6-7.73,6.08-9.61C459.55,175.29,470.45,171.73,479.39,171.19Z"
      transform="translate(128.99 48.24)"
    />
    <path
      d="M650.7,535.45a9.62,9.62,0,0,1,1.84-5.12c3.32-5.56,6.43-11.26,10-16.7,1.77-2.74,1.35-4-1.38-5.67-12-7.4-21.17-17.1-24.76-31.23-1.68-6.59-2.24-13.14.13-19.65a16.59,16.59,0,0,1,2.61-4.78c1.68-2.07,3.68-4.24,6.73-3.16s3.12,3.85,3.14,6.64c.09,16.1,6.75,28.82,19.81,38.25,2.4,1.73,3.66,1.67,5.08-1.29,5.75-12,11.4-24,16-36.53,2.11-5.76,4.34-7.34,8.46-5.95,5.45,1.84,7.94,6.48,6.2,11.67-9.14,27.32-22.94,52.33-38.2,76.61-2.12,3.37-5.14,5.11-9.29,3.66C653.72,541,650.89,539.47,650.7,535.45Z"
      transform="translate(128.99 48.24)"
    />
    <path
      d="M98.05,216c-.13,6.05-5.64,10-9.83,7.24-12.08-8-25.52-7.72-38.95-7.1-15.77.73-31.59.71-47.27,2.9-5.59.78-11,2.05-16,5.05-13.07,7.85-26.3,15.42-39.51,23-5.29,3.05-9.33,2.31-11.54-1.93-2.09-4-.52-7.58,4.49-10.49,12.46-7.24,25.05-14.29,37.32-21.85a59.06,59.06,0,0,1,27.3-8.51c21.23-1.51,42.47-3.53,63.77-2.32,9.68.55,19,2.95,27,9.08C96.86,212.64,98.05,214.43,98.05,216Z"
      transform="translate(128.99 48.24)"
    />
    <path
      d="M473.83,220a30.45,30.45,0,0,1,16.42,3.55c3.15,1.74,6.72,1.8,10.13,2.32A33.7,33.7,0,0,1,519,234.59c13.21,12.65,27.29,24.33,40.93,36.49,10.23,9.11,17.23,20.55,24.14,32.07,2.46,4.11,1.51,7.86-1.92,10-3.63,2.28-7.61,1.32-10.25-2.62-1.48-2.2-2.87-4.48-4.13-6.81-7.77-14.39-20.14-24.53-32.13-35-7.75-6.79-15.73-13.36-23-20.63-5.53-5.52-11.91-8.14-19.39-9-2.12-.24-4.49-.42-6.25-1.46-8.62-5.1-17.63-3.5-26.68-2.07a46.27,46.27,0,0,1-4.92.71c-3.92.17-6.83-1.27-7.6-5.49s1.35-7.45,5.83-8.43A83.48,83.48,0,0,1,473.83,220Z"
      transform="translate(128.99 48.24)"
    />
    <path
      d="M94.62,495.15a238.49,238.49,0,0,1,2.5-39.76c.67-4.63,4.16-6.89,9.6-6.32,4.32.46,6.43,2.77,6.38,7.29-.18,15-1.24,29.91-2.56,44.82a39.76,39.76,0,0,0,.39,12.41c1.39,6.21,4.37,8.65,11,8.13,10-.77,19.52-3.6,29.15-6,4.22-1,7.6-1.43,9.07,3.64,1.62,5.63-.62,10.18-6,11.77A373.65,373.65,0,0,1,116.35,540c-2.61.48-5.21,1-7.8,1.57A9.25,9.25,0,0,1,97,533C95.8,519.25,94.6,505.5,94.62,495.15Z"
      transform="translate(128.99 48.24)"
    />
    <path
      d="M479.18,277c12.38,1,22.74,7.2,32.73,14.21,11.22,7.88,18.67,19.27,27.22,29.62,6.33,7.68,12.44,15.56,18.65,23.35.41.52.85,1,1.23,1.56,3,4.24,2.8,8.68-.43,11.13s-7.66,1.66-10.84-2.28c-7.41-9.16-14.72-18.4-22-27.65-9.48-12-18.9-23.85-33.08-31.11-9.2-4.72-17.94-5.2-27.27-1.55a44.84,44.84,0,0,1-4.62,1.78c-4.06,1.1-7.31,0-9-4.06s.11-7.23,3.77-9.05C462.87,279.3,470.81,277.65,479.18,277Z"
      transform="translate(128.99 48.24)"
    />
    <path
      d="M52.88,247.19a82.46,82.46,0,0,1,30.5,8.2,6.72,6.72,0,0,1,3.13,9.35c-1.89,3.52-4.91,5-8.79,3.52-5.73-2.16-11.4-4.48-17.42-5.74-3.57-.75-7.53-1.87-10.79-.17-14.64,7.68-31,12-44.5,21.83A169.67,169.67,0,0,0-15.27,301.9c-2.74,2.76-6,3.68-9.5,1.62-2.92-1.7-3.7-4.53-3.18-7.76.35-2.13,2-3.44,3.37-4.81,8.22-8,16.56-16.1,26.44-21.94,13.83-8.17,28.87-14,43.63-20.27C48,247.66,51,247.57,52.88,247.19Z"
      transform="translate(128.99 48.24)"
    />
    <path
      d="M383.38,314.72c-8.52-.59-16.18-.43-23.66-2.44-4-1.07-5.34-2.69-4.05-7.08,2.48-8.44,4.51-17,9-24.68,6.95-11.87,17-12.52,25.38-1.67A60.44,60.44,0,0,1,401.74,306c1,5.73-.6,7.65-6.32,8C391.11,314.35,386.79,314.53,383.38,314.72Z"
      transform="translate(128.99 48.24)"
    />
    <path
      d="M184.49,290.06c-7.65-.67-18.15-2-28.23-5.66-4.22-1.52-6.22-3.35-3.8-8.43,3.64-7.63,6.6-15.55,11.61-22.49,7.67-10.61,18.92-10.73,25.8,1.4a66.64,66.64,0,0,1,7.8,24C199.08,288.9,197.55,290.68,184.49,290.06Z"
      transform="translate(128.99 48.24)"
    />
    <path
      d="M185-23.11c13.12-.19,24.53,3.43,35.46,9,3.61,1.85,5,4.68,3.09,8.12s-4.95,3.16-8.22,1.41a65.24,65.24,0,0,0-25.5-7.72C176-13.45,163.94-9.37,153.75.07c-1.83,1.69-3.46,3.59-5.26,5.32-2.24,2.15-4.83,2.43-7.18.44s-2.89-4.77-.81-7.49a54.07,54.07,0,0,1,12.4-11.47C162.74-19.95,173.6-23.52,185-23.11Z"
      transform="translate(128.99 48.24)"
    />
    <path
      d="M189.93,2c8.44-.11,16.53,1.81,24.5,4.22,3.54,1.07,6.78,3.29,5.23,7.57s-5.09,3.91-8.8,2.69A67.45,67.45,0,0,0,187.33,13c-8.94.29-16.64,3-22.33,10.14-2.47,3.09-5.45,4.33-8.53,1.76s-2-5.85.33-8.79C165.36,5.49,177,2.15,189.93,2Z"
      transform="translate(128.99 48.24)"
    />
    <path
      d="M434.81-14c9.48,0,17.36,4.14,24.44,9.88A63.9,63.9,0,0,1,471,8.05c2,2.78,2.6,5.57-.35,7.9s-5.75,1.68-8.13-1.27c-4.08-5.06-8.47-9.8-14-13.35-9-5.78-18-6.4-26.83.24-3,2.29-6.15,3.3-8.71-.07-2.75-3.63-.67-6.45,2.48-8.78C421.19-11.54,427.54-14.06,434.81-14Z"
      transform="translate(128.99 48.24)"
    />
    <path
      d="M423.9,6.82c15,.85,25.82,9.2,35.17,20,2,2.3,1.34,5.14-1.1,7.05-2.64,2.08-5.29,1.6-7.55-.75-4.85-5.05-9.95-9.77-16.32-12.86-6.61-3.21-13.19-4-19.87.05-2.79,1.71-5.93,1.88-7.86-1.25s-.83-6,2.38-8C413.38,8.23,418.28,6.44,423.9,6.82Z"
      transform="translate(128.99 48.24)"
    />
    <path
      d="M195.26,20.1c6,.72,11.71,1.75,16.88,4.67,3.19,1.8,4.1,4.49,2.5,7.57s-4.27,3.5-7.26,2a28.13,28.13,0,0,0-9.9-3.13c-8.37-.75-14.38,3.21-18.69,10.06-2,3.22-4.62,5.19-8.21,2.91-3.86-2.46-2.94-5.69-.71-9C176.19,25.69,185.72,20,195.26,20.1Z"
      transform="translate(128.99 48.24)"
    />
    <path
      d="M422.6,24.33c5.83.44,9.77,2.83,13.33,5.94a71,71,0,0,1,12.38,14.88c2.16,3.26,3.16,6.56-.74,9.05-3.64,2.32-6.18.21-8.2-2.92a57.28,57.28,0,0,0-11.93-13.94c-2.26-1.82-4.48-3.19-7.44-.55-2.3,2.07-5.08,1.42-7-1s-1.69-4.94.41-7.19A13.39,13.39,0,0,1,422.6,24.33Z"
      transform="translate(128.99 48.24)"
    />
    <path
      d="M267.82,45.06c-4.79.25-8.65-.86-12.59-1.34-3.46-.43-4-2.44-3.07-5.23A27.43,27.43,0,0,1,260.06,26c4-3.41,8.61-2.56,11.24,2.05a25.64,25.64,0,0,1,3,14.41C274,47.27,269.38,43.86,267.82,45.06Z"
      transform="translate(128.99 48.24)"
    />
    <path
      d="M363.36,49.06c-9.76.38-10.07.09-8-9.37,1.06-4.8,1.68-10.51,7.82-11.36,5.75-.8,8.2,4.24,10.68,8.23,5.65,9.12,4.2,11.73-6.55,12.48C366,49.14,364.68,49.06,363.36,49.06Z"
      transform="translate(128.99 48.24)"
    />
  </SVGStyle>
);

export default Logo;

export const fruitData = [
  {
    name: 'Strawberries',
  },
  {
    name: 'Koalaberries (Blackberries)',
  },
  {
    name: 'Raspberries',
  },
  {
    name: 'Mango',
  },
  {
    name: 'Kiwi',
  },
  {
    name: 'Pineapple',
  },
  {
    name: 'Blueberries',
  },
  {
    name: 'Mandarin Oranges',
  },
  {
    name: 'Maraschino Cherries',
  },
];

export default {
  fruitData,
};
